import styled, { css } from 'styled-components'
import { device, fonts, host, WarOffset } from '../../../styles/GlobalVariables'
import AdClose from '/public/assets/images/svg/ad-close.svg'
import XMark from '/public/assets/images/svg/x-mark.svg'
import { he } from '../../../locales/he'

const topPlacement = ({
  bg,
  isFaceLift,
}: {
  bg: string
  isFaceLift?: boolean
}) => css`
  border-bottom: 1px solid #dfdfdf;
  min-height: 200px;
  background-color: ${bg};
  transition: height 0.5s ease;

  ${device.medium} {
    min-height: 290px;
    margin-bottom: ${isFaceLift ? '48px' : '28px'};
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '${he.ad}';
    font-family: ${fonts.default};
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #7b8588;
  }
`
export const ContainerPlaceHolder = styled.div`
  ${props =>
    props.theme.pageType === 'vod'
      ? topPlacement({ bg: '#1e1e1e' })
      : topPlacement({ bg: '#f8f8f8' })}
`
export const Container = styled.div<{ isFaceLift?: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => (props.isFaceLift ? '24px' : '0')};

  ${props =>
    props.theme.pageType === 'vod'
      ? topPlacement({ bg: '#1e1e1e', isFaceLift: !!props.isFaceLift })
      : topPlacement({ bg: '#f8f8f8', isFaceLift: !!props.isFaceLift })}
  ${props => WarOffset(props.theme.haseWarComp, true)}

  > div {
    position: relative;
    z-index: 1;
  }

  ${host.nativeApp} & {
    padding: 20px 0;
    min-height: 160px;
    margin: 0;
  }

  #Top_mobile {
    margin: auto;
  }
`

export const InboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const AdCloseIcon = styled(AdClose)`
  position: absolute;
  font-size: 38px;
  top: 10px;
  z-index: 1;
  left: 10px;
  cursor: pointer;
`

export const AdCloseIcon2 = styled(XMark)`
  position: absolute;
  font-size: 28px;
  top: 12px;
  z-index: 1;
  left: 12px;
  cursor: pointer;
  border: 1px solid rgba(77, 81, 100, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

  ${device.medium} {
    font-size: 32px;
  }
`
