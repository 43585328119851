import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isAndroidApp, isDesktop } from '../../../client'
import { usePage, useWindow } from '../../../contexts'
import {
  IGridVodItem,
  isGridVodPage,
  isLiveVodPage,
  isVodPlaylist,
} from '../../../types'
import Ad from '../Ad'
import {
  AdCloseIcon,
  AdCloseIcon2,
  Container,
  ContainerPlaceHolder,
  InboardContainer,
} from './TopBanner.styles'

export const TopBanner = () => {
  const page = usePage()
  const { asPath } = useRouter()
  const win = useWindow()

  if (!win || !page) {
    return (
      <ContainerPlaceHolder
        theme={{ pageType: page?.PageMeta.applicationPageType || '' }}
      />
    )
  }
  if (
    isLiveVodPage(page) ||
    (page?.Content?.PageGrid &&
      isVodPlaylist(page?.Content?.PageGrid[0] as IGridVodItem)) ||
    isGridVodPage(page)
  ) {
    return null
  }
  return <TopBannerComp key={asPath} />
}
const TopBannerComp = () => {
  const [renderFirstAd, setRenderFirstAd] = useState(
    isDesktop() || isAndroidApp()
  )
  const [renderButton, setRenderButton] = useState(false)

  const containerRef = useRef<HTMLImageElement>(null)

  const router = useRouter()
  const page = usePage()

  useEffect(() => {
    setRenderFirstAd(isDesktop() || isAndroidApp())
  }, [router.asPath])

  useEffect(() => {
    const onRouteChangeStart = () => {
      setRenderButton(false)
    }
    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [router.events])

  const onRender = useCallback(
    (event: googletag.events.SlotRenderEndedEvent) => {
      // If the ad unit does not fill render top ad
      if (event.isEmpty) {
        setRenderFirstAd(false)
      } else {
        setTimeout(() => setRenderButton(true), 1000)
      }
    },
    []
  )
  if (!page) {
    return null
  }
  const { isFaceLift = false } = page.PageMeta

  return (
    <Container
      ref={containerRef}
      isFaceLift={isFaceLift}
      theme={{
        haseWarComp: !!page?.PageMeta.war_comp,
        pageType: page.PageMeta.applicationPageType,
      }}
    >
      {renderFirstAd ? (
        <InboardContainer>
          {renderButton ? (
            isFaceLift ? (
              <AdCloseIcon2 onClick={() => setRenderFirstAd(false)} />
            ) : (
              <AdCloseIcon onClick={() => setRenderFirstAd(false)} />
            )
          ) : null}
          <Ad
            desktopAdId='Inboard'
            mobileAdId='Maavaron_main'
            collapse={true}
            onRender={event => onRender(event)}
          />
        </InboardContainer>
      ) : (
        <Ad desktopAdId='Top' mobileAdId='Top_mobile' collapse={true} />
      )}
    </Container>
  )
}
