// TODO: remove this \/
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { isNativeApp } from '../../client'
import { usePage } from '../../contexts'
import { fonts } from '../../styles/GlobalVariables'
import { isGridVodPage, isVodPlaylist } from '../../types'
import { utils } from '../Player/Helpers/utils'
import TaboolaDetails from './TaboolaDetails'
import { Props } from './types'

const StyledTaboolaDiv = styled.div`
  // &[id^='taboola'] {
  //   *,
  //   .video-title {
  //     font-family: ${fonts.default} !important;
  //   }
  // }
`

let isFirstPage = true,
  lastPath = ''

const TaboolaDiv = ({ type, options, id, pub, suffixId }: Props) => {
  const showDetails = useRef(false)
  useEffect(() => type && !isNativeApp() && window._taboola.push(type), [type])

  const page = usePage()
  const router = useRouter()

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const canonial_url = page
      ? page.SEO.canonical
      : window.location.origin + window.location.pathname

    if (isFirstPage || lastPath === window.location.pathname) {
      lastPath = window.location.pathname
      isFirstPage = false
      return
    }

    if (
      page &&
      isGridVodPage(page) &&
      isVodPlaylist(page?.Content?.PageGrid?.[0])
    ) {
      return
    }
    const chromeLess = isNativeApp() ? '?chromeless=true' : ''
    lastPath = window.location.pathname
    window._taboola.push({ ...type, url: canonial_url + chromeLess })

    const tb_log =
      sessionStorage.getItem('tb_log') === 'true' ||
      utils.getParameterByName('tb_log') === 'true'
    sessionStorage.setItem('tb_log', String(tb_log))
    tb_log &&
      console.log(
        '%cTaboolaDiv:144',
        'background-color:darkblue;color:white;padding:2px 5px;',
        '\nTaboola new Page Load',
        '\ntype: ',
        type,
        '\nurl: ',
        canonial_url + chromeLess
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, type])

  useEffect(() => {
    const canonial_url = page
      ? page.SEO.canonical
      : window.location.origin + window.location.pathname
    if (isNativeApp()) {
      window._taboola.push({
        ...type,
        url: canonial_url + '?chromeless=true',
      })
      window._taboola.push({
        ...options,
      })
      const tb_log =
        sessionStorage.getItem('tb_log') === 'true' ||
        utils.getParameterByName('tb_log') === 'true'
      sessionStorage.setItem('tb_log', String(tb_log))
      tb_log &&
        console.log(
          '%cTaboolaDiv:116',
          'background-color:darkblue;color:white;padding:2px 5px;',
          '\nisNativeApp: true',
          '\nid: ' + id,
          '\npublisher: ' + pub
        )
      window._taboola['mobile'] = []
      window._taboola['mobile'].push({
        allow_sdkless_load: false,
        taboola_view_id: new Date().getTime(),
        publisher: pub,
      })
    } else {
      const tb_log =
        sessionStorage.getItem('tb_log') === 'true' ||
        utils.getParameterByName('tb_log') === 'true'
      sessionStorage.setItem('tb_log', String(tb_log))
      tb_log &&
        console.log(
          '%cTaboolaDiv:130',
          'background-color:darkblue;color:white;padding:2px 5px;',
          '\nurl: ' + canonial_url,
          '\n',
          options
        )
      window._taboola.push({
        ...options,
        url: canonial_url,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pub, options, type, router.asPath])

  useEffect(() => {
    showDetails.current =
      utils.getParameterByName('tb_placements') == 'true' ||
      sessionStorage.getItem('tb_placements') === 'true'
    sessionStorage.setItem('tb_placements', String(showDetails.current))
  }, [])

  return (
    <>
      {showDetails.current && TaboolaDetails(options)}
      <StyledTaboolaDiv ref={ref} id={id} theme={suffixId} />
    </>
  )
}

export default TaboolaDiv
