import React from 'react'
import { TaboolaForDiv } from './TaboolaForDiv'
import {
  PocTaboolaList,
  PocTaboolaPageType,
  PocTaboolaSuffixId,
} from './TaboolaTypes'
import { utils } from '../Player/Helpers/utils'
import { useTaboola } from './useTaboola'

type Props = {
  pageType: PocTaboolaPageType | typeof PocTaboolaList | string
  index?: number
  suffixId: PocTaboolaSuffixId
}

export const Taboola = ({ pageType, index, suffixId }: Props) => {
  const { isTaboolaReady, os, taboolaPubName } = useTaboola()

  if (!isTaboolaReady) {
    return null
  }

  return (
    <TaboolaForDiv
      key={`${pageType}-${suffixId}-${index}-${utils.getPageViewedInSession()}`}
      pageType={pageType}
      index={index}
      suffixId={suffixId}
      os={os}
      pub={taboolaPubName}
    />
  )
}
